import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BooksGrid from "../components/books-grid";
import SectionTitle from "../components/sectiontitle";
import Seo from "../components/seo";

export default function BooksIndex({ data }) {
  return (
    <Layout>
      <Seo lang="en" title="Books" />
      {data.allMarkdownRemark.edges.length > 0
        ? <section id="books" className="container">
          <div className="section-title">
            <SectionTitle title="Latest Reads" />
            <i>
              <strong><u>Excellent!</u></strong> ﹥
              Great! ﹥
              Decent ﹥
              Bad. ﹥
              Terrible. ☠️
            </i>
          </div>
          <BooksGrid data={data} />
        </section>
        : <>
        </>
      }
    </Layout>
  );
}

export const query = graphql`
query {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/book/"}}
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          author
          stars
          cover {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 200,
                placeholder: BLURRED
              )
              id
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
