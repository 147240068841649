import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "../style/list-project.less";

const groupBy = (array, keyCallback) => {
  return array.reduce((result, x) => {
    (result[keyCallback(x)] = result[keyCallback(x)] || []).push(x);
    return result;
  }, {});
};

export default function BooksGrid({ data }) {
  const booksByYear = groupBy(data.allMarkdownRemark.edges, book => (new Date(book.node.frontmatter.date)).getFullYear())
  // Cloned with book-show.js
  const bookRankingStyle = {
    5: "<strong><u>Excellent!</u></strong>",
    4: "Great!",
    3: "Decent",
    2: "Bad.",
    1: "Terrible."
  }

  return (
    <div>
      {Object.keys(booksByYear).sort().reverse().map(year =>
        <div key={year}>
          <h6 style={{ color: "#F55555" }}>{year}</h6>
          <hr style={{ xborderTop: "1px solid #F55555" }} />
          <div className="books-grid">
            {booksByYear[year].map(book =>
              <Link
                key={book.node.id}
                to={book.node.fields.slug}
                title={book.node.frontmatter.title}
                aria-label={book.node.frontmatter.title}
                style={{ color: "inherit" }}
              >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
                  <div className="image">
                    <GatsbyImage image={book.node.frontmatter.cover.childImageSharp.gatsbyImageData} alt="" imgStyle={{ borderRadius: "10px", border: "solid #F55555" }} />
                  </div>
                  <div className="center-text">
                    <p style={{ color: "#6888DF", paddingTop: "10px" }}>
                      {book.node.frontmatter.title}
                    </p>
                    <p>
                      {book.node.frontmatter.author}
                    </p>
                  </div>
                  <div>
                    <i dangerouslySetInnerHTML={{ __html: bookRankingStyle[parseInt(book.node.frontmatter.stars)] }} ></i>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
